import * as urlSlug from "url-slug"
import { format, register } from "timeago.js"
import idLocale from "timeago.js/lib/lang/id_ID"
register("id_ID", idLocale)

export function permalinkToText(permalink: any) {
    return urlSlug.revert(permalink, {
        camelCase: true,
        transformer: urlSlug.TITLECASE_TRANSFORMER,
    })
}

export function permalink(text: any) {
    return urlSlug.convert(text, {
        separator: "-",
        camelCase: false,
    })
}

export function escapeString(string: any) {
    return string
        .replace("\\", "\\\\")
        .replace("'", "\\'")
        .replace('"', '\\"')
        .replace("\n", "\\\n")
        .replace("\r", "\\\r")
        .replace("\x00", "\\\x00")
        .replace("\x1a", "\\\x1a")
}

export function containsNonLatinCodepoints(s: string) {
    return /[^\u0000-\u00ff]/.test(s)
}

export function formatDate(date: string) {
    const newDate = new Date(date)
    return format(newDate, "id_ID")
}

export function isNumeric(num: any) {
    return !isNaN(num)
}

export function stringToArray(str: string, separator = ", ") {
    const datas: string[] = []
    if (!str) return datas
    if (str.includes(", ")) {
        str.split(", ").map((item) => {
            datas.push(item)
        })
    } else {
        datas.push(str)
    }
    return datas
}

export function encodeEscapeJson(str: string) {
    return encodeURIComponent(str.replace(/%/g, "%25"))
}

export function cleanSubtitleName(str: string) {
    return str.length > 40 ? str.replace(/\./g, " ") : str
}

export const encryptId = ((e) => {
    const t = (e: any) => e.split("").map((e: any) => e.charCodeAt(0)),
        n = (e: any) => ("0" + Number(e).toString(16)).substr(-2),
        o = (n: any) => t(e).reduce((e: any, t: any) => e ^ t, n)
    return (e: any) => e.split("").map(t).map(o).map(n).join("")
})("subsc")

export const decryptId = ((e) => {
    const a = (a: any) => {
        return ((t = e), t.split("").map((e) => e.charCodeAt(0))).reduce(
            (e, a) => e ^ a,
            a,
        )
        var t
    }
    return (e: any) =>
        e
            .match(/.{1,2}/g)
            .map((e: any) => parseInt(e, 16))
            .map(a)
            .map((e: any) => String.fromCharCode(e))
            .join("")
})("subsc")
