interface Props {
    children: React.ReactNode
    className?: string
}
export const Page: React.FC<Props> = ({ children, className }) => {
    return (
        <section
            className={`pt-16 pb-16 lg:pb-4 md:max-w-6xl mx-auto ${className}`}
        >
            {children}
        </section>
    )
}
