import { ImageLoaderProps } from "next/image";

export function WPLoader({ src, width, quality }: ImageLoaderProps) {
    // if(src.includes("media-amazon.com")){
    //     const cdnSrc = src.replace("m.media-amazon.com/images/M/", `i2.wp.com/${process.env.IMAGE_CDN}/img-m/`)
    //     return `${src}?w=${width}&q=${quality || 90}`
    // } else {
    //     const cdnSrc = src.replace("i.jeded.com/i/", `i2.wp.com/${process.env.IMAGE_CDN}/poster/`)
    //     return `${src}?w=${width}&q=${quality || 90}`
    // }
    return `${src}?w=${width}&q=${quality || 70}`
}
